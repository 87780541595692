/*
 * @Author: wangqs 
 * @description 测试模块
 * @Date: 2021-07-11 16:24:29 
 * @Last Modified by: wangqs
 * @Last Modified time: 2023-04-06 20:37:16
 */
<template>
  <div class="page-box box">
    <van-empty
      v-if="logs.length === 0"
      description="还没有上课记录哦！"
    />
    <div
      v-for="(item, index) of logs"
      :key="index"
      class="van-cell"
    >
      <div class="box item-box">
        <div class="top-box">
          <van-image
            class="img"
            :src="imgUrl"
          ></van-image>
          {{ item.memo || '扫码核销' }}
        </div>
        <div
          class="text-a"
          style="margin-top: 4px;"
        >
          核销人员：{{ item.cancelName || '-' }}
        </div>
        <div class="text-a">
          核销课次：{{ item.cancelTimes }}
        </div>
        <div class="text-a">
          剩余课次：{{ item.unCancelTimes || 0 }}
        </div>
        <div class="text-a">
          核销时间：{{ item.cancelDate || '-' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Test',
  components: {
  },
  data () {
    return {
      logs: [],
      imgUrl: require('@/assets/img/qrcode-logo.png')
    }
  },
  computed: {
  },
  created () {
    this.getLogs()
  },
  methods: {
    async getLogs () {
      console.log(this.$route.query)
      let data = {
        id: this.$route.query.orderNo || '',
        pageSize: 1000,
        page: 1
      }
      let res = await this.$http.userApi.courseLog(data)
      console.log(res, 'ress')
      if (res.code == 200) {
        this.logs = res.msg || []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page-box {
  width: 100%;
  background: #ECF1FF;
  .item-box {
    padding: 0 14px 10px 14px;
    background: #fff;
    margin-bottom: 10px;
    .top-box {
      height: 44px;
      border-bottom: 0.5px solid #E4E4EA;
      font-size: 15px;
      line-height: 43px;
      color: #3D4258;
      padding-left: 25px;
      position: relative;
      /deep/ .img {
        height: 20px;
        width: 20px;
        position: absolute;
        left: 0;
        top: 12px;
      }
    }
    .text-a {
      line-height: 29px;
      color: #3D4258;
      font-size: 15px;
    }
  }
  .van-cell {
    .van-cell__title {
      .text-a {
        font-size: 16px;
        font-weight: 400;
        color: #3D4258;
      }
      .text-b {
        font-weight: 400;
        color: #8F92A9;
        font-size: 13px;
      }
    }
    .van-cell__value {
      font-weight: 400;
      color: #3D4258;
      line-height: 46px;
    }
  }
  .van-cell:last-child::after {
    border-bottom: 1px solid #ebedf0;
  }
}
</style>
